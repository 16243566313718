import { apiBaseUrl } from 'util/api';
import { getPath } from 'util/routes';

export const useSocialAuth = () => {
  const getDefaultRoutes = from => {
    const baseUrl = apiBaseUrl();
    const urlParams = new URLSearchParams(window.location.search);
    const referralCode = urlParams.get('referralCode');
    const fromParam = from ? `from=${from}` : '';
    const referralCodeParam = referralCode ? `?referralCode=${referralCode}` : '';
    const defaultReturn = getPath('LandingPage');
    const defaultReturnParam = defaultReturn ? `&defaultReturn=${defaultReturn}` : '';
    const defaultConfirm = getPath('ConfirmPage');
    const defaultConfirmParam = defaultConfirm ? `&defaultConfirm=${defaultConfirm}` : '';

    return { baseUrl, fromParam, defaultReturnParam, defaultConfirmParam, referralCodeParam };
  };

  const authWithFacebook = from => {
    const { baseUrl, fromParam, defaultReturnParam, defaultConfirmParam, referralCodeParam } =
      getDefaultRoutes(from);
    window.location.href = `${baseUrl}/api/auth/facebook?${fromParam}${defaultReturnParam}${defaultConfirmParam}${referralCodeParam}`;
  };

  const authWithGoogle = from => {
    const { baseUrl, fromParam, defaultReturnParam, defaultConfirmParam, referralCodeParam } =
      getDefaultRoutes(from);
    window.location.href = `${baseUrl}/api/auth/google?${fromParam}${defaultReturnParam}${defaultConfirmParam}${referralCodeParam}`;
  };

  return { authWithFacebook, authWithGoogle };
};
