import Cookies from 'js-cookie';
import { isEmpty } from 'remeda';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';

import {
  Button,
  ImageLazy,
  LayoutSingleColumn,
  NamedLink,
  NamedRedirect,
  Page,
  PrimaryButton,
} from '../../components';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';
import { useConfiguration } from '../../context/configurationContext';
import { useLogin, useSignup, useSignupWithIdp } from '../../hooks/api/auth';
import {
  isHandleTakenError,
  isLoginGuestAccountError,
  isSignupEmailTakenError,
} from '../../util/errors';
import { FormattedMessage, useIntl } from '../../util/reactIntl';
import css from './AuthenticationPage.module.css';
import ConfirmSignupForm from './ConfirmSignupForm/ConfirmSignupForm';
import EmailVerificationInfo from './EmailVerificationInfo/EmailVerificationInfo';
import LoginForm from './LoginForm/LoginForm';
import SignupForm from './SignupForm/SignupForm';
// We need to get ToS asset and get it rendered for the modal on this page.
import { useCurrentUser } from 'hooks/selectors/useCurrentUser';
import { useMediaQueries } from 'hooks/useMediaQueries';

// Tabs for SignupForm and LoginForm
export const AuthenticationForms = props => {
  const { isLogin, from, submitLogin, loginError, signupError, authInProgress, submitSignup } =
    props;

  const loginErrorMessage = (
    <div className={css.error}>
      {isLoginGuestAccountError(loginError) ? (
        <FormattedMessage id="AuthenticationPage.guestAccount" />
      ) : (
        <FormattedMessage id="AuthenticationPage.loginFailed" />
      )}
    </div>
  );

  const signupErrorMessage = (
    <div className={css.error}>
      {isSignupEmailTakenError(signupError) ? (
        <FormattedMessage id="AuthenticationPage.signupFailedEmailAlreadyTaken" />
      ) : isHandleTakenError(signupError) ? (
        <FormattedMessage id="AuthenticationPage.handleTakenError" />
      ) : (
        <FormattedMessage id="AuthenticationPage.signupFailed" />
      )}
    </div>
  );

  // eslint-disable-next-line no-confusing-arrow
  const errorMessage = (error, message) => (error ? message : null);
  const loginOrSignupError = isLogin
    ? errorMessage(loginError, loginErrorMessage)
    : errorMessage(signupError, signupErrorMessage);

  return isLogin ? (
    <LoginForm
      className={css.loginForm}
      onSubmit={submitLogin}
      inProgress={authInProgress}
      from={from}
      errorMessage={loginOrSignupError}
    />
  ) : (
    <SignupForm
      className={css.signupForm}
      onSubmit={submitSignup}
      inProgress={authInProgress}
      from={from}
      errorMessage={loginOrSignupError}
    />
  );
};

// Form for confirming information from IdP (e.g. Facebook)
// This is shown before new user is created to Marketplace API
const ConfirmIdProviderInfoForm = props => {
  const { authInfo, authInProgress, confirmError, submitSingupWithIdp, referralCode } = props;
  const idp = authInfo ? authInfo.idpId.replace(/^./, str => str.toUpperCase()) : null;

  const handleSubmitConfirm = values => {
    const { idpToken, email, idpId } = authInfo;
    const { email: newEmail, firstName, lastName, handle, dateOfBirth, ...rest } = values;
    const modifiedHandle = handle?.replace('@', '');

    // Pass email, fistName or lastName to Marketplace API only if user has edited them
    // and they can't be fetched directly from idp provider (e.g. Facebook)

    const authParams = {
      ...(newEmail !== email && { email: newEmail }),
      firstName,
      lastName,
      handle: modifiedHandle,
      dateOfBirth,
    };

    // If the confirm form has any additional values, pass them forward as user's protected data
    const protectedData = !isEmpty(rest) ? { ...rest } : null;

    submitSingupWithIdp({
      idpToken,
      idpId,
      referralCode,
      ...authParams,
      ...(!!protectedData && { protectedData }),
    });
  };

  const confirmErrorMessage = confirmError ? (
    <div className={css.error}>
      {confirmError.data?.errors?.[0]?.displayMessage ? (
        confirmError.data.errors[0].displayMessage
      ) : isHandleTakenError(confirmError) ? (
        <FormattedMessage id="AuthenticationPage.handleTakenError" />
      ) : (
        <FormattedMessage id="AuthenticationPage.signupFailed" />
      )}
    </div>
  ) : null;

  return (
    <div>
      <ConfirmSignupForm
        onSubmit={handleSubmitConfirm}
        inProgress={authInProgress}
        authInfo={authInfo}
        idp={idp}
        errorMessage={confirmErrorMessage}
      />
    </div>
  );
};

export const AuthenticationOrConfirmInfoForm = props => {
  const {
    tab,
    authInfo,
    from,
    showFacebookLogin,
    showGoogleLogin,
    submitLogin,
    submitSignup,
    submitSingupWithIdp,
    authInProgress,
    loginError,
    signupError,
    signupWithIdpError,
  } = props;
  const isConfirm = tab === 'confirm';
  const isLogin = tab === 'login';

  return isConfirm ? (
    <ConfirmIdProviderInfoForm
      authInfo={authInfo}
      submitSingupWithIdp={submitSingupWithIdp}
      authInProgress={authInProgress}
      confirmError={signupWithIdpError}
    />
  ) : (
    <AuthenticationForms
      isLogin={isLogin}
      showFacebookLogin={showFacebookLogin}
      showGoogleLogin={showGoogleLogin}
      from={from}
      loginError={loginError}
      signupError={signupError}
      submitLogin={submitLogin}
      authInProgress={authInProgress}
      submitSignup={submitSignup}
    ></AuthenticationForms>
  );
};

const getAuthInfoFromCookies = () => {
  return Cookies.get('st-authinfo')
    ? JSON.parse(Cookies.get('st-authinfo')!.replace('j:', ''))
    : null;
};
const getAuthErrorFromCookies = () => {
  return Cookies.get('st-autherror')
    ? JSON.parse(Cookies.get('st-autherror')!.replace('j:', ''))
    : null;
};

export const AuthenticationPage: React.FC<{
  tab?: 'login' | 'signup' | 'confirm' | 'welcome';
}> = ({ tab = 'signup' }) => {
  const [tosModalOpen] = useState(false);
  const [privacyModalOpen] = useState(false);
  const [authInfo] = useState(getAuthInfoFromCookies());
  const [authError] = useState(getAuthErrorFromCookies());
  const config = useConfiguration();

  useEffect(() => {
    // Remove the autherror cookie once the content is saved to state
    // because we don't want to show the error message e.g. after page refresh
    if (authError) {
      Cookies.remove('st-autherror');
    }
  }, []);

  // On mobile, it's better to scroll to top.
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [tosModalOpen, privacyModalOpen]);

  const intl = useIntl();
  const location = useLocation<{ from?: any }>();
  const { mutate: submitLogin, error: loginError, isLoading: isLoggingIn } = useLogin();
  const { mutate: submitSignup, error: signupNormalError, isLoading: isSigningUp } = useSignup();
  const {
    mutate: submitSignupWithIdp,
    error: signupWithIdpError,
    isLoading: isSigningUpWithIdp,
  } = useSignupWithIdp();
  const signupError = signupNormalError || signupWithIdpError;
  const authInProgress = isLoggingIn || isSigningUp || isSigningUpWithIdp;

  // History API has potentially state tied to this route
  // We have used that state to store previous URL ("from"),
  // so that use can be redirected back to that page after authentication.
  const locationFrom = location.state?.from || null;
  const authinfoFrom = authInfo?.from || null;
  const from = locationFrom ? locationFrom : authinfoFrom ? authinfoFrom : null;

  const { currentUser: user } = useCurrentUser();
  const currentUserLoaded = !!user.id;
  const isLogin = tab === 'login';

  // We only want to show the email verification dialog in the signup
  // tab if the user isn't being redirected somewhere else
  // (i.e. `from` is present). We must also check the `emailVerified`
  // flag only when the current user is fully loaded.
  const showEmailVerification = Boolean(
    !isLogin && currentUserLoaded && !user.attributes.emailVerified
  );

  const isDesktop = useMediaQueries({ viewport: 'large' });
  const isAuthenticated = useIsAuthenticated();

  // Already authenticated, redirect away from auth page
  if (isAuthenticated && from) {
    return <Redirect to={from} />;
  } else if (isAuthenticated && currentUserLoaded && !showEmailVerification) {
    return <NamedRedirect name="LandingPage" />;
  }

  const marketplaceName = config.marketplaceName;
  const schemaTitle = isLogin
    ? intl.formatMessage({ id: 'AuthenticationPage.schemaTitleLogin' }, { marketplaceName })
    : intl.formatMessage({ id: 'AuthenticationPage.schemaTitleSignup' }, { marketplaceName });
  const schemaDescription = isLogin
    ? intl.formatMessage({ id: 'AuthenticationPage.schemaDescriptionLogin' }, { marketplaceName })
    : intl.formatMessage({ id: 'AuthenticationPage.schemaDescriptionSignup' }, { marketplaceName });

  if (tab === 'welcome' && isDesktop) {
    return <NamedRedirect name="SignupPage" state={{ from }} />;
  }

  return (
    <Page
      title={schemaTitle}
      schema={{
        '@context': 'https://schema.org',
        '@type': 'WebPage',
        name: schemaTitle,
        description: schemaDescription,
      }}
    >
      <LayoutSingleColumn topbar={<TopbarContainer />}>
        <div
          className={css.contentWrapper}
          style={{
            ...(tab === 'welcome' && {
              alignContent: 'start',
              justifyItems: 'center',
            }),
          }}
        >
          <ImageLazy
            className={css.backgroundImage}
            src={
              isDesktop
                ? 'https://cdn.thenold.com/authentication-bg-desktop.jpg'
                : 'https://cdn.thenold.com/authentication-bg.jpg'
            }
            blurSrc={
              isDesktop
                ? 'https://cdn.thenold.com/authentication-bg-desktop.blur.jpg'
                : 'https://cdn.thenold.com/authentication-bg.blur.jpg'
            }
          />
          {(isDesktop || tab === 'welcome') && (
            <div className={css.textParagraph}>
              <p>Fastest listing.</p>
              <p>100% payout.</p>
              <p>Finest curation.</p>
              <span>It's a game changer. Become one too.</span>
            </div>
          )}
          {tab === 'welcome' && (
            <div className={css.welcomeButtons}>
              <NamedLink name="LoginPage" to={{ state: { from } }}>
                <PrimaryButton>Log in</PrimaryButton>
              </NamedLink>
              <NamedLink name="SignupPage" to={{ state: { from } }}>
                <Button>Sign up</Button>
              </NamedLink>
            </div>
          )}
          {tab !== 'welcome' && (
            <div className={css.content}>
              {showEmailVerification && (
                <EmailVerificationInfo
                  name={user.attributes.profile.firstName}
                  email={<span className={css.email}>{user.attributes.email}</span>}
                  from={from}
                />
              )}
              {!showEmailVerification && (
                <AuthenticationOrConfirmInfoForm
                  tab={tab}
                  authInfo={authInfo}
                  from={from}
                  submitLogin={submitLogin}
                  submitSignup={submitSignup}
                  submitSingupWithIdp={submitSignupWithIdp}
                  authInProgress={authInProgress}
                  loginError={loginError}
                  signupError={signupError}
                  signupWithIdpError={signupWithIdpError}
                />
              )}
            </div>
          )}
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

const useIsAuthenticated = () => useSelector((state: any) => Boolean(state?.auth?.isAuthenticated));

export default AuthenticationPage;
