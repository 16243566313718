import { useHistory } from 'react-router-dom';

import { Button, InlineTextButton } from '../../../components';
import { FormattedMessage } from '../../../util/reactIntl';
import css from './EmailVerificationInfo.module.css';
import { useSendVerificationEmail } from 'hooks/api/users';
import { isTooManyEmailVerificationRequestsError } from 'util/errors';

const EmailVerificationInfo = props => {
  const { name, email, from } = props;

  const {
    mutate: onResendVerificationEmail,
    isLoading: sendVerificationEmailInProgress,
    error: sendVerificationEmailError,
  } = useSendVerificationEmail();

  const resendErrorTranslationId = isTooManyEmailVerificationRequestsError(
    sendVerificationEmailError
  )
    ? 'AuthenticationPage.resendFailedTooManyRequests'
    : 'AuthenticationPage.resendFailed';
  const resendErrorMessage = sendVerificationEmailError ? (
    <p className={css.error}>
      <FormattedMessage id={resendErrorTranslationId} />
    </p>
  ) : null;

  const history = useHistory();

  const resendEmailLink = (
    <InlineTextButton onClick={onResendVerificationEmail} className={css.linkButton}>
      <FormattedMessage id="AuthenticationPage.resendEmailLinkText" />
    </InlineTextButton>
  );

  return (
    <div className={css.root}>
      <h1>Hey, {name}!</h1>
      <p>Thanks for signing up! There's one quick step left. </p>

      <p>
        Please verify your email address so we can contact you.
        <br /> Click the link we sent to {email}.
      </p>

      {resendErrorMessage}
      <div>
        {sendVerificationEmailInProgress ? (
          <FormattedMessage id="AuthenticationPage.sendingEmail" />
        ) : (
          <FormattedMessage id="AuthenticationPage.resendEmail" values={{ resendEmailLink }} />
        )}
      </div>

      <Button onClick={() => history.push(from || '/')}>Continue</Button>
    </div>
  );
};

export default EmailVerificationInfo;
