import classNames from 'classnames';
import arrayMutators from 'final-form-arrays';
import { bool } from 'prop-types';
import { useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { compose } from 'redux';

import {
  Button,
  FieldTextInput,
  FieldUsername,
  Form,
  NamedLink,
  PrimaryButton,
} from '../../../components';
import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import { useSocialAuth } from '../AuthenticationPage.hooks';
import css from '../AuthenticationPageCommon.module.css';
import { FacebookIcon, GoogleIcon } from '../LoginForm/LoginForm';
import { Checkbox } from 'components/FieldCheckboxV2/FieldCheckbox';
import FieldFullDateInput from 'components/FieldFullDateInput/FieldFullDateInput';
import { LegalPageTab } from 'containers/LegalPage/LegalPage.constants';

const SignupFormComponent = props => {
  const { from, errorMessage } = props;
  const { authWithFacebook, authWithGoogle } = useSocialAuth();
  const [googleAuthInProgress, setGoogleAuthInProgress] = useState(false);
  const [facebookAuthInProgress, setFacebookAuthInProgress] = useState(false);
  const [marketingConsent, setMarketingConsent] = useState(false);

  const handleSubmitForm = async (values: any) => {
    const { fname, lname, handle, email, ...rest } = values;
    const modifiedHandle = handle ? handle.replace('@', '') : fname + lname[0];

    const params = {
      firstName: fname.trim(),
      lastName: lname.trim(),
      handle: modifiedHandle,
      marketingConsent,
      email,
      ...rest,
    };

    await props.onSubmit(params);
  };

  const isInAppBrowser = () => {
    if (typeof window === 'undefined') {
      return false;
    }

    const userAgent = navigator.userAgent;
    return /FBAN|FBAV|Instagram|Twitter/i.test(userAgent);
  };

  return (
    <FinalForm
      {...props}
      mutators={{ ...arrayMutators }}
      onSubmit={handleSubmitForm}
      render={fieldRenderProps => {
        // @ts-expect-error TS(2339) FIXME: Property 'rootClassName' does not exist on type 'F... Remove this comment to see the full error message
        const { rootClassName, className, formId, handleSubmit, inProgress, intl } =
          fieldRenderProps;

        // email
        const emailRequired = validators.required(
          intl.formatMessage({
            id: 'SignupForm.emailRequired',
          })
        );
        const emailValid = validators.emailFormatValid(
          intl.formatMessage({
            id: 'SignupForm.emailInvalid',
          })
        );

        // password
        const passwordRequiredMessage = intl.formatMessage({
          id: 'SignupForm.passwordRequired',
        });
        const passwordMinLengthMessage = intl.formatMessage(
          {
            id: 'SignupForm.passwordTooShort',
          },
          {
            minLength: validators.PASSWORD_MIN_LENGTH,
          }
        );
        const passwordMaxLengthMessage = intl.formatMessage(
          {
            id: 'SignupForm.passwordTooLong',
          },
          {
            maxLength: validators.PASSWORD_MAX_LENGTH,
          }
        );
        const passwordMinLength = validators.minLength(
          passwordMinLengthMessage,
          validators.PASSWORD_MIN_LENGTH
        );
        const passwordMaxLength = validators.maxLength(
          passwordMaxLengthMessage,
          validators.PASSWORD_MAX_LENGTH
        );
        const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
        const passwordValidators = validators.composeValidators(
          passwordRequired,
          passwordMinLength,
          passwordMaxLength
        );

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = inProgress;

        return (
          // @ts-expect-error TS(2322) FIXME: Type '{ children: Element[]; className: string; on... Remove this comment to see the full error message
          <Form className={classes} onSubmit={handleSubmit}>
            {!isInAppBrowser() && (
              <div className={css.idpButtons}>
                <Button
                  type="button"
                  inProgress={facebookAuthInProgress}
                  onClick={() => {
                    setFacebookAuthInProgress(true);
                    authWithFacebook(from);
                  }}
                >
                  <FacebookIcon /> <FormattedMessage id="SignupForm.continueWithFacebook" />
                </Button>
                <Button
                  type="button"
                  inProgress={googleAuthInProgress}
                  onClick={() => {
                    setGoogleAuthInProgress(true);
                    authWithGoogle(from);
                  }}
                >
                  <GoogleIcon /> <FormattedMessage id="Continue with Google" />
                </Button>
              </div>
            )}
            {!isInAppBrowser() && (
              <div className={css.divider}>
                <div className={css.dividerLine}></div>
                <div className={css.dividerText}>
                  <FormattedMessage id="SignupForm.orSignUpWithEmail" />
                </div>
                <div className={css.dividerLine}></div>
              </div>
            )}
            {isInAppBrowser() && (
              <div className={css.divider}>
                <div className={css.dividerLine}></div>
                <div className={css.dividerText}>
                  Want to sign up with Google or Facebook? Open a browser outside of Instagram and
                  sign in faster!
                </div>
                <div className={css.dividerLine}></div>
              </div>
            )}
            <div className={classNames(css.fieldsContainer, css['fieldsContainer--signup'])}>
              <FieldTextInput
                type="email"
                id={formId ? `${formId}.email` : 'email'}
                name="email"
                autoComplete="email"
                label={intl.formatMessage({
                  id: 'SignupForm.emailLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.emailPlaceholder',
                })}
                validate={validators.composeValidators(emailRequired, emailValid)}
              />
              <FieldTextInput
                type="password"
                id={formId ? `${formId}.password` : 'password'}
                name="password"
                autoComplete="new-password"
                label={intl.formatMessage({
                  id: 'SignupForm.passwordLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.passwordPlaceholder',
                })}
                validate={passwordValidators}
              />
              <FieldTextInput
                type="text"
                id={formId ? `${formId}.fname` : 'fname'}
                name="fname"
                autoComplete="given-name"
                label={intl.formatMessage({
                  id: 'SignupForm.firstNameLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.firstNamePlaceholder',
                })}
                validate={validators.composeValidators(
                  validators.required(
                    intl.formatMessage({
                      id: 'SignupForm.firstNameRequired',
                    })
                  ),
                  validators.lettersOnly(
                    intl.formatMessage({
                      id: 'SignupForm.firstNameInvalid',
                    })
                  )
                )}
              />
              <FieldTextInput
                type="text"
                id={formId ? `${formId}.lname` : 'lname'}
                name="lname"
                autoComplete="family-name"
                label={intl.formatMessage({
                  id: 'SignupForm.lastNameLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.lastNamePlaceholder',
                })}
                validate={validators.composeValidators(
                  validators.required(
                    intl.formatMessage({
                      id: 'SignupForm.lastNameRequired',
                    })
                  ),
                  validators.lettersOnly(
                    intl.formatMessage({
                      id: 'SignupForm.lastNameInvalid',
                    })
                  )
                )}
              />
              <FieldUsername />
              <FieldFullDateInput
                placeholder="DD/MM/YYYY"
                label={intl.formatMessage({ id: 'SignupForm.dobLabel' })}
                id={formId ? `${formId}.dateOfBirth` : 'dateOfBirth'}
                name="dateOfBirth"
                type="text"
                validate={validators.composeValidators(
                  validators.required(intl.formatMessage({ id: 'SignupForm.dobRequiredMessage' })),
                  validators.isValidDate(intl.formatMessage({ id: 'SignupForm.dobInvalidMessage' }))
                )}
              />

              <div className={css.marketingConsentCheckbox}>
                <Checkbox
                  label={intl.formatMessage({ id: 'ProfileAddressesForm.marketingConsent' })}
                  id={formId ? `${formId}.marketingConsent` : 'marketingConsent'}
                  name="marketingConsent"
                  checked={marketingConsent}
                  onCheckedChange={(checked: boolean) => setMarketingConsent(checked)}
                />
              </div>
            </div>

            {errorMessage}

            <PrimaryButton type="submit" inProgress={submitInProgress}>
              <FormattedMessage id="SignupForm.signUp" />
            </PrimaryButton>
            <span className={css.agreeToTnc}>
              By signing up you accept THE NOLD's{' '}
              <NamedLink name="LegalPage" params={{ tab: LegalPageTab.TermsAndConditions }}>
                Terms & Conditions
              </NamedLink>{' '}
              and{' '}
              <NamedLink name="LegalPage" params={{ tab: LegalPageTab.PrivacyPolicy }}>
                Privacy Policy
              </NamedLink>
            </span>

            <div className={css.logIn}>
              <FormattedMessage id="SignupForm.haveAccount" />{' '}
              <NamedLink name="LoginPage" to={{ state: { from } }}>
                <FormattedMessage id="SignupForm.logIn" />
              </NamedLink>
            </div>
          </Form>
        );
      }}
    />
  );
};

SignupFormComponent.defaultProps = { inProgress: false };

SignupFormComponent.propTypes = {
  inProgress: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
