import classNames from 'classnames';
import arrayMutators from 'final-form-arrays';
import { useState } from 'react';
import { Form as FinalForm } from 'react-final-form';

import { FieldTextInput, FieldUsername, Form, PrimaryButton } from '../../../components';
import { FormattedMessage, useIntl } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import css from '../AuthenticationPageCommon.module.css';
import { Checkbox } from 'components/FieldCheckboxV2/FieldCheckbox';
import FieldFullDateInput from 'components/FieldFullDateInput/FieldFullDateInput';
import { useSubscribeToNewsletter } from 'containers/PageBuilder/SectionBuilder/SectionFooter/SectionFooter.hooks';

const ConfirmSignupForm = props => {
  const intl = useIntl();

  const [marketingConsentChecked, setMarketingConsentChecked] = useState(false);

  const { mutateAsync: subscribeToNewsletter } = useSubscribeToNewsletter();

  const onSubmitHandle = values => {
    const { email, firstName, lastName } = values;
    if (email && marketingConsentChecked) {
      try {
        subscribeToNewsletter({ email, firstName, lastName });
      } catch (e) {
        console.error('Failed to subscribe to newsletter', e);
      }
    }

    props.onSubmit(values);
  };

  return (
    <FinalForm
      {...props}
      mutators={{ ...arrayMutators }}
      onSubmit={onSubmitHandle}
      render={formRenderProps => {
        const {
          // @ts-expect-error TS(2339) FIXME: Property 'rootClassName' does not exist on type 'F... Remove this comment to see the full error message
          rootClassName,
          // @ts-expect-error TS(2339) FIXME: Property 'className' does not exist on type 'FormR... Remove this comment to see the full error message
          className,
          // @ts-expect-error TS(2339) FIXME: Property 'formId' does not exist on type 'FormRend... Remove this comment to see the full error message
          formId,
          handleSubmit,
          // @ts-expect-error TS(2339) FIXME: Property 'inProgress' does not exist on type 'Form... Remove this comment to see the full error message
          inProgress,
          // @ts-expect-error TS(2339) FIXME: Property 'termsAndConditions' does not exist on ty... Remove this comment to see the full error message
          termsAndConditions,
          // @ts-expect-error TS(2339) FIXME: Property 'authInfo' does not exist on type 'FormRe... Remove this comment to see the full error message
          authInfo,
          // @ts-expect-error TS(2339) FIXME: Property 'idp' does not exist on type 'FormRenderP... Remove this comment to see the full error message
          idp,
          // @ts-expect-error TS(2339) FIXME: Property 'idp' does not exist on type 'FormRenderP... Remove this comment to see the full error message
          errorMessage,
        } = formRenderProps;

        // email
        const emailRequired = validators.required(
          intl.formatMessage({
            id: 'ConfirmSignupForm.emailRequired',
          })
        );
        const emailValid = validators.emailFormatValid(
          intl.formatMessage({
            id: 'ConfirmSignupForm.emailInvalid',
          })
        );

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = inProgress;

        // Initial values from idp provider
        const { email, firstName, lastName } = authInfo || {};

        return (
          // @ts-expect-error TS(2322) FIXME: Type '{ children: Element[]; className: string; on... Remove this comment to see the full error message
          <Form className={classes} onSubmit={handleSubmit}>
            <h1 className={css.title}>
              <FormattedMessage
                id="AuthenticationPage.confirmSignupWithIdpTitle"
                values={{ idp }}
              />
            </h1>
            <p>
              <FormattedMessage id="AuthenticationPage.confirmSignupInfoText" />
            </p>
            <div className={classNames(css.fieldsContainer, css['fieldsContainer--socialConfirm'])}>
              <FieldTextInput
                type="email"
                id={formId ? `${formId}.email` : 'email'}
                name="email"
                autoComplete="email"
                label={intl.formatMessage({
                  id: 'ConfirmSignupForm.emailLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'ConfirmSignupForm.emailPlaceholder',
                })}
                disabled
                initialValue={email}
                validate={validators.composeValidators(emailRequired, emailValid)}
              />
              <FieldUsername
                formId={formId}
                initialValue={`${(firstName && lastName
                  ? firstName + lastName[0]
                  : firstName || lastName || ''
                ).toLowerCase()}`}
              />
              <FieldFullDateInput
                placeholder="DD/MM/YYYY"
                label={intl.formatMessage({ id: 'SignupForm.dobLabel' })}
                id={formId ? `${formId}.dateOfBirth` : 'dateOfBirth'}
                name="dateOfBirth"
                type="text"
                validate={validators.composeValidators(
                  validators.required(intl.formatMessage({ id: 'SignupForm.dobRequiredMessage' })),
                  validators.isValidDate(intl.formatMessage({ id: 'SignupForm.dobInvalidMessage' }))
                )}
              />
              <FieldTextInput
                type="text"
                id={formId ? `${formId}.firstName` : 'firstName'}
                name="firstName"
                autoComplete="given-name"
                label={intl.formatMessage({
                  id: 'ConfirmSignupForm.firstNameLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'ConfirmSignupForm.firstNamePlaceholder',
                })}
                initialValue={firstName}
                validate={validators.composeValidators(
                  validators.required(
                    intl.formatMessage({
                      id: 'ConfirmSignupForm.firstNameRequired',
                    })
                  ),
                  validators.lettersOnly(
                    intl.formatMessage({
                      id: 'ConfirmSignupForm.firstNameInvalid',
                    })
                  )
                )}
              />
              <FieldTextInput
                type="text"
                id={formId ? `${formId}.lastName` : 'lastName'}
                name="lastName"
                autoComplete="family-name"
                label={intl.formatMessage({
                  id: 'ConfirmSignupForm.lastNameLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'ConfirmSignupForm.lastNamePlaceholder',
                })}
                initialValue={lastName}
                validate={validators.composeValidators(
                  validators.required(
                    intl.formatMessage({
                      id: 'ConfirmSignupForm.lastNameRequired',
                    })
                  ),
                  validators.lettersOnly(
                    intl.formatMessage({
                      id: 'ConfirmSignupForm.lastNameInvalid',
                    })
                  )
                )}
              />
              <div className={css.marketingConsentCheckbox}>
                <Checkbox
                  label={intl.formatMessage({ id: 'ProfileAddressesForm.marketingConsent' })}
                  id={formId ? `${formId}.marketingConsent` : 'marketingConsent'}
                  name="marketingConsent"
                  checked={marketingConsentChecked}
                  onCheckedChange={(checked: boolean) => setMarketingConsentChecked(checked)}
                />
              </div>
            </div>
            {termsAndConditions && <div className={css.logIn}>{termsAndConditions}</div>}
            {errorMessage}
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={!authInfo}>
              <FormattedMessage id="ConfirmSignupForm.signUp" values={{ idp: idp }} />
            </PrimaryButton>
          </Form>
        );
      }}
    />
  );
};

export default ConfirmSignupForm;
