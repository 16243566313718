import classNames from 'classnames';
import PropTypes, { any } from 'prop-types';
import { useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { compose } from 'redux';

import { Button, FieldTextInput, Form, NamedLink, PrimaryButton } from '../../../components';
import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import { useSocialAuth } from '../AuthenticationPage.hooks';
import css from '../AuthenticationPageCommon.module.css';

export const FacebookIcon = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
      <g clip-path="url(#clip0_2827_11830)">
        <path
          d="M12.5 0.09375C19.1274 0.09375 24.5 5.46633 24.5 12.0938C24.5 18.0833 20.1118 23.0477 14.375 23.948V15.5625H17.1711L17.7031 12.0938H14.375V9.84274C14.375 8.89376 14.8399 7.96875 16.3306 7.96875H17.8438V5.01562C17.8438 5.01562 16.4705 4.78125 15.1576 4.78125C12.4165 4.78125 10.625 6.4425 10.625 9.45V12.0938H7.57812V15.5625H10.625V23.948C4.88823 23.0477 0.5 18.0833 0.5 12.0938C0.5 5.46633 5.87258 0.09375 12.5 0.09375Z"
          fill="var(--n-color-text-dark)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2827_11830">
          <rect width="24" height="24" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const GoogleIcon = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
      <path
        d="M16.2717 5.94547L20.1593 2.76273C18.081 1.03719 15.4112 0 12.4995 0C7.8615 0 3.83807 2.63086 1.84084 6.48155C0.982997 8.13391 0.5 10.0109 0.5 12.0008C0.5 14.0587 1.01761 15.9953 1.93016 17.6878L1.93157 17.6878C3.95855 21.4466 7.93172 24.0007 12.5016 24.0007C15.3454 24.0007 17.9578 23.0116 20.0144 21.3586C22.1282 19.6588 23.6535 17.2565 24.2376 14.5062C24.4097 13.6979 24.5 12.8598 24.5 12.0006C24.5 11.2344 24.4281 10.4847 24.2903 9.75881H12.7634V14.5062H19.1803C18.6027 16.0478 17.5062 17.3356 16.1031 18.1585C15.0465 18.7783 13.8156 19.134 12.5016 19.134C9.44277 19.134 6.83366 17.2086 5.82023 14.5039C5.52749 13.7252 5.36679 12.8817 5.36669 12.0008C5.36669 11.1892 5.50254 10.4091 5.75178 9.68251L5.75119 9.68117C6.71412 6.87998 9.37179 4.86674 12.4995 4.86674C13.8848 4.86674 15.1778 5.26172 16.2717 5.94547Z"
        fill="var(--n-color-text-dark)"
      />
    </svg>
  );
};

const LoginFormComponent = props => {
  const { from } = props;
  const { authWithFacebook, authWithGoogle } = useSocialAuth();
  const [googleAuthInProgress, setGoogleAuthInProgress] = useState(false);
  const [facebookAuthInProgress, setFacebookAuthInProgress] = useState(false);
  const isInAppBrowser = () => {
    if (typeof window === 'undefined') {
      return false;
    }

    const userAgent = navigator.userAgent;
    return /FBAN|FBAV|Instagram|Twitter/i.test(userAgent);
  };

  return (
    <FinalForm
      {...props}
      render={fieldRenderProps => {
        // @ts-expect-error TS(2339) FIXME: Property 'rootClassName' does not exist on type 'F... Remove this comment to see the full error message
        const { rootClassName, className, formId, handleSubmit, inProgress, intl, errorMessage } =
          fieldRenderProps;

        // email
        const emailLabel = intl.formatMessage({
          id: 'LoginForm.emailLabel',
        });
        const emailPlaceholder = intl.formatMessage({
          id: 'LoginForm.emailPlaceholder',
        });
        const emailRequiredMessage = intl.formatMessage({
          id: 'LoginForm.emailRequired',
        });
        const emailRequired = validators.required(emailRequiredMessage);
        const emailInvalidMessage = intl.formatMessage({
          id: 'LoginForm.emailInvalid',
        });
        const emailValid = validators.emailFormatValid(emailInvalidMessage);

        // password
        const passwordLabel = intl.formatMessage({
          id: 'LoginForm.passwordLabel',
        });
        const passwordPlaceholder = intl.formatMessage({
          id: 'LoginForm.passwordPlaceholder',
        });
        const passwordRequiredMessage = intl.formatMessage({
          id: 'LoginForm.passwordRequired',
        });
        const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = inProgress;

        const passwordRecoveryLink = (
          <NamedLink name="PasswordRecoveryPage">
            <FormattedMessage id="LoginForm.forgotPassword" />
          </NamedLink>
        );

        return (
          // @ts-expect-error TS(2322) FIXME: Type '{ children: Element[]; className: string; on... Remove this comment to see the full error message
          <Form className={classes} onSubmit={handleSubmit}>
            {!isInAppBrowser() && (
              <div className={css.idpButtons}>
                <Button
                  type="button"
                  inProgress={facebookAuthInProgress}
                  onClick={() => {
                    setFacebookAuthInProgress(true);
                    authWithFacebook(from);
                  }}
                >
                  <FacebookIcon /> <FormattedMessage id="LoginForm.continueWithFacebook" />
                </Button>
                <Button
                  type="button"
                  inProgress={googleAuthInProgress}
                  onClick={() => {
                    setGoogleAuthInProgress(true);
                    authWithGoogle(from);
                  }}
                >
                  <GoogleIcon /> <FormattedMessage id="LoginForm.continueWithGoogle" />
                </Button>
              </div>
            )}
            {isInAppBrowser() && (
              <div className={css.divider}>
                <div className={css.dividerLine}></div>
                <div className={css.dividerText}>
                  Want to sign up with Google or Facebook? Open a browser outside of Instagram and
                  sign in faster!
                </div>
                <div className={css.dividerLine}></div>
              </div>
            )}
            <div className={css.divider}>
              <div className={css.dividerLine}></div>
              <div className={css.dividerText}>
                <FormattedMessage id="LoginForm.orLogInWithEmail" />
              </div>
              <div className={css.dividerLine}></div>
            </div>
            <div className={css.fieldsContainer}>
              <FieldTextInput
                type="email"
                id={formId ? `${formId}.email` : 'email'}
                name="email"
                autoComplete="email"
                label={emailLabel}
                placeholder={emailPlaceholder}
                validate={validators.composeValidators(emailRequired, emailValid)}
              />
              <FieldTextInput
                type="password"
                id={formId ? `${formId}.password` : 'password'}
                name="password"
                autoComplete="current-password"
                label={passwordLabel}
                placeholder={passwordPlaceholder}
                validate={passwordRequired}
              />
              <span className={css.link}>
                <FormattedMessage
                  id="LoginForm.forgotPasswordInfo"
                  values={{ passwordRecoveryLink }}
                />
              </span>
            </div>
            {errorMessage}
            <PrimaryButton type="submit" inProgress={submitInProgress}>
              <FormattedMessage id="LoginForm.logIn" />
            </PrimaryButton>
            <div className={css.logIn}>
              <FormattedMessage id="LoginForm.dontHaveAccount" />
              <NamedLink name="SignupPage" to={{ state: { from } }}>
                <FormattedMessage id="LoginForm.signUp" />
              </NamedLink>
            </div>
          </Form>
        );
      }}
    />
  );
};

LoginFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  form: null,
  inProgress: false,
  from: null,
};

const { string, bool } = PropTypes;

LoginFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  form: string,
  inProgress: bool,
  intl: intlShape.isRequired,
  from: string,
  errorMessage: any,
};

const LoginForm = compose(injectIntl)(LoginFormComponent);
LoginForm.displayName = 'LoginForm';

export default LoginForm;
